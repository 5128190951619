<template>
  <el-dialog
    class="award-custom-dialog custom-dialog"
    :title="`${
      data && data.award_id ? '修改股票授予记录' : '新建股票授予记录'
    }`"
    :visible.sync="visible"
    :before-close="handleBeforeClose"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
    width="576px"
  >
    <div>
      <el-form
        ref="form"
        :model="info"
        v-loading="getDetailLoading"
        :rules="formRules"
        hide-required-asterisk
        label-width="70px"
        class="custom-award"
      >
        <div class="form__title mt-24">参与人信息</div>
        <div class="from_item__both flex-h flex-hw">
          <el-form-item label="手机号" prop="phone">
            <el-input
              label=""
              v-model="info.phone"
              placeholder="输入参与人手机号"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="姓名">
            {{ userInfo.name || "---" }}
          </el-form-item>
          <el-form-item label="在职状态" class="from_item__height_none">
            {{
              tableMng.getNameById("employ_status", userInfo.employ_status) ||
              "---"
            }}
          </el-form-item>
          <el-form-item label="所属部门" class="from_item__height_none">
            <TextOverflow :limit="10" :text="userInfo.department || '- -'" />
          </el-form-item>
        </div>
        <div class="form__title">股票激励计划信息</div>
        <el-form-item label="计划名称" prop="plan_id">
          <el-select v-model="info.plan_id" placeholder="请选择激励计划">
            <el-option
              v-for="item in planList"
              :key="item.plan_id"
              :label="item.name"
              :value="item.plan_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div class="from_item__three plan flex-h flex-hw">
          <el-form-item label="计划编号" class="from_item__height_none">
            {{ planInfo.code || "---" }}
            <!-- <TextOverflow :limit="6" :text="planInfo.code || '- -'" /> -->
          </el-form-item>
          <el-form-item label="币种" class="from_item__height_none">
            {{ planInfo.currency || "---" }}
          </el-form-item>
          <el-form-item label="认购参考价" class="from_item__height_none w-85">
            {{ planInfo.price || "---" }}
          </el-form-item>
          <el-form-item label="持股方式" class="from_item__height_none">
            {{ tableMng.getNameById("hold_mode", planInfo.mode) || "---" }}
          </el-form-item>
          <el-form-item
            label="持股平台"
            class="from_item__height_none width-auto"
          >
            <TextOverflow :limit="15" :text="planInfo.platform_name || '- -'" />
          </el-form-item>
        </div>
        <el-form-item label="剩余股数" class="from_item__height_none">
          {{ planInfo.surplus || "---" }}
        </el-form-item>

        <div class="form__title mt-24">授予信息</div>
        <div class="from_item__both flex-h flex-hw">
          <el-form-item label="授予日期" prop="award_date">
            <el-date-picker
              v-model="info.award_date"
              type="date"
              placeholder="请选择日期"
              :value-format="valueFormat"
              :clearable="false"
              :editable="false"
            />
          </el-form-item>
          <el-form-item label="起算日期" prop="start_date">
            <el-date-picker
              v-model="info.start_date"
              type="date"
              :value-format="valueFormat"
              placeholder="请选择日期"
              :clearable="false"
              :editable="false"
            />
          </el-form-item>
          <el-form-item label="授予股数" prop="award_count">
            <el-input
              label=""
              v-model="info.award_count"
              placeholder="请输入股数，整数"
              v-number-format="{ limit: 0, min: 1 }"
              clearable
            ></el-input>
          </el-form-item>
        </div>

        <div class="from_item__both flex-h flex-hw">
          <el-form-item label="成熟条件" prop="condition_id">
            <el-select v-model="info.condition_id" placeholder="请选择成熟条件">
              <el-option
                v-for="item in conditionList"
                :key="item.condition_id"
                :label="item.name"
                :value="item.condition_id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="条件编号">
            {{ conditionInfo.code || "---" }}
          </el-form-item>
        </div>
        <el-form-item label="条件说明" class="from_item__height_none">
          <!-- {{ conditionInfo.content || "---" }} -->
          <template
            v-if="conditionInfo.content && conditionInfo.content.length > 0"
          >
            <div
              class="condition"
              v-for="(item, idx) in conditionShow(conditionInfo.content || [])"
              :key="idx"
            >
              {{ item }}
            </div>
          </template>
          <span v-else>---</span>
        </el-form-item>
        <div class="from_item__both flex-h flex-hw">
          <el-form-item label="出资方式" prop="buy_mode">
            <el-select v-model="info.buy_mode" placeholder="请选择出资方式">
              <el-option
                v-for="item in tableMng.getTable('buy_mode')"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="授予状态">
            {{ tableMng.getNameById("award_status", info.status) || "---" }}
          </el-form-item>
        </div>

        <el-form-item label="备注" prop="remark">
          <el-input
            label=""
            type="textarea"
            v-model="info.remark"
            placeholder="请输入备注信息"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleBeforeClose">取消</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import api from "@/api";
import dialogLife from "@/mixins/dialogLife";
import TextOverflow from "@/components/base/text-overflow";
import {
  deepClone,
  debounce,
  conditionShow,
  formatDecimal,
} from "@/utils/core";
import dayjs from "dayjs";
const defaultInfo = {
  user_id: "",
  plan_id: "",
  award_date: dayjs().format("YYYY-MM-DD"),
  start_date: dayjs().format("YYYY-MM-DD"),
  status: "normal",
  award_count: "",
  buy_mode: "",
  condition_id: "",
  award_id: "",
  remark: "",
};

export default {
  mixins: [dialogLife],
  components: {
    TextOverflow,
  },
  data() {
    const validateUser = (rule, value, callback) => {
      console.log(value);
      if (!this.info.user_id || !value) {
        callback(new Error("请输入正确的参与人手机号"));
      } else {
        callback();
      }
    };
    return {
      conditionShow,
      infoBackup: deepClone(defaultInfo),
      info: deepClone(defaultInfo),
      planInfo: {},
      userInfo: {},
      conditionInfo: {},
      planList: [],
      conditionList: [],
      valueFormat: "yyyy-MM-dd",
      formRules: {
        phone: [{ validator: validateUser, trigger: "blur" }],
        plan_id: [
          { required: true, message: "请选择激励计划", trigger: "change" },
        ],
        condition_id: [
          { required: true, message: "请选择成熟条件", trigger: "change" },
        ],
        award_count: [
          { required: true, message: "请输入授予股数", trigger: "blur" },
        ],
        price: [
          {
            required: true,
            message: "请输入行权参考价",
            trigger: "blur",
          },
        ],
        currency: [
          { required: true, message: "请选择币种", trigger: "change" },
        ],
        buy_mode: [
          { required: true, message: "请选择出资方式", trigger: "change" },
        ],
        other_currency: [
          { required: true, message: "请输入币种", trigger: "blur" },
        ],
        total: [
          {
            required: true,
            message: "请输入总股数",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    ["info.plan_id"](val, oval) {
      if (val !== oval) {
        debounce(async () => {
          const plan = this.planList.filter((item) => item.plan_id === val);
          if (plan.length < 1) {
            this.planInfo = {};
            return;
          }
          this.planInfo = plan[0];
        }, 100)();
      }
    },
    ["info.condition_id"](val, oval) {
      if (val !== oval) {
        debounce(async () => {
          const condition = this.conditionList.filter(
            (item) => item.condition_id === val
          );
          if (condition.length < 1) {
            this.conditionInfo = {};
            return;
          }
          this.conditionInfo = condition[0];
        }, 100)();
      }
    },
    ["info.phone"](val, oval) {
      if (val !== oval) {
        debounce(async () => {
          const res = await api.participants.staffList({
            limit: -1,
            phone: val,
          });
          if (res && res.list.length > 0) {
            const userInfo = res.list.filter((item) => item.phone == val);
            if (userInfo.length < 1) {
              this.userInfo = {};
              return;
            }
            this.info.user_id = userInfo[0].user_id;
            this.userInfo = userInfo[0];
          }
        }, 100)();
      }
    },
  },
  methods: {
    async getDetail() {
      // 计划列表
      const planList = await api.incentive.stockPlanList({ limit: -1 });
      if (planList.list) this.planList = planList.list;
      // 条件列表
      const conditionList = await await api.condition.conditionList({
        limit: -1,
      });
      if (conditionList.list) this.conditionList = conditionList.list;

      if (!this.data || !this.data.plan_id) return;
      console.log(this.data);
      this.getDetailLoading = true;
      let data = deepClone(this.data);
      this.info = data;
      this.infoBackup = data;
      this.getDetailLoading = false;
    },
    async submitMethod() {
      const {
        user_id,
        award_id,
        plan_id,
        award_date,
        start_date,
        award_count,
        condition_id,
        buy_mode,
        remark,
      } = this.info;
      if (award_id)
        await api.awarded.stockAwardEdit({
          user_id,
          award_id,
          plan_id,
          award_date,
          start_date,
          award_count: formatDecimal(award_count, 0),
          condition_id,
          buy_mode,
          remark,
        });
      else
        await api.awarded.stockAwardAdd({
          user_id,
          plan_id,
          award_date,
          start_date,
          award_count: formatDecimal(award_count, 0),
          condition_id,
          buy_mode,
          remark,
        });
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.info = deepClone(defaultInfo);
      this.infoBackup = deepClone(defaultInfo);
      this.$emit("onClose");
    },
  },
};
</script>

<style lang="scss" scoped>
.award-custom-dialog {
  .w-100 {
    width: 100%;
  }
  /deep/ .el-dialog__body {
    padding-top: 0px;
    .el-form {
      padding-top: 0px;
    }
  }
  .custom-award {
    .condition {
      line-height: 22px;
    }
    .mt-24 {
      margin-top: 24px;
    }
    .form__title {
      font-size: 14px;
      color: $black;
      line-height: 22px;
      font-weight: 600;
      padding-bottom: 12px;
      padding-top: 12px;
    }
    .w-85 /deep/.el-form-item__label {
      width: 85px !important;
    }
    .from_item__height_none {
      &.width-auto {
        width: auto !important;
      }
      /deep/ .el-form-item__label,
      /deep/ .el-form-item__content {
        line-height: 1;
      }
    }
    .from_item__both {
      /deep/.el-form-item {
        width: calc(50% - 16px);
        &:nth-child(2n) {
          margin-left: 32px;
        }
      }
    }
    // .from_item__three {
    //   /deep/.el-form-item {
    //     width: calc(33.33% - 20px);
    //     &:nth-child(3n - 1) {
    //       margin-left: 30px;
    //       margin-right: 30px;
    //     }
    //   }
    // }
    // .from_item__three.plan {
    //   /deep/.el-form-item {
    //     width: calc(27.33% - 20px);
    //     &:nth-child(3n) {
    //       width: calc(39.33% - 20px);
    //     }
    //     &:nth-child(3n - 1) {
    //       margin-left: 0px;
    //       margin-right: 0px;
    //     }
    //     &:nth-child(3n - 2) {
    //       width: calc(33.33% + 40px);
    //     }
    //   }
    // }
    .from_item__three {
      /deep/.el-form-item {
        width: calc(33.33% - 20px);
        &:nth-child(3n - 1) {
          margin-left: 18px;
          margin-right: 18px;
        }
      }
    }
    .from_item__three.plan {
      /deep/.el-form-item {
        width: calc(27.33% - 20px);
        &:nth-child(3n) {
          width: calc(39.33% - 26px);
        }
        &:nth-child(3n - 2) {
          width: calc(33.33% + 10px);
        }
      }
    }
  }
}
</style>
