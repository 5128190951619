<template>
  <div class="right-sta" v-loading="loading">
    <div class="flex-h flex-vc flex-jsb">
      <div class="left">
        <ChartNum
          name="授予人次"
          :num="option_stat.number"
          backgroundImage=""
          shadowColor=""
        />
        <ChartNum
          v-for="item in optionStatRe"
          :key="item.color"
          :name="item.name"
          :num="item.percent"
          :tipText="item.tipText || ''"
          :backgroundImage="item.lineColor"
          :shadowColor="item.shadowColor"
        />
      </div>
      <div class="chart-box">
        <Ring
          :name="'已授予'"
          :subtext="
            parseFloat(data.total) == 0
              ? '0%'
              : (
                  (
                    parseFloat(option_stat.award_qty) / parseFloat(data.total)
                  ).toFixed(2) * 100
                ).toFixed(2) + '%'
          "
          :value="
            parseFloat(data.total) == 0
              ? 0
              : (
                  (parseFloat(option_stat.award_qty) / parseFloat(data.total)) *
                  100
                ).toFixed(2)
          "
          :color1="'#866BFF'"
          :color2="'#6e51ed'"
          :color3="'#5A39EB'"
          :id="'sychart4'"
          :subColor="'#ECF0F3'"
          :shadowcolor="'rgba(134,107,255,0.5)'"
          :height="172"
        ></Ring>
      </div>
    </div>
    <div class="flex-h flex-vc flex-jsb mt-64">
      <div class="left">
        <ChartNum
          name="授予总数(股)"
          :num="option_stat.award_qty"
          backgroundImage=""
          shadowColor=""
        />
        <ChartNum
          name="归属总数(股)"
          :num="option_stat.actual_qty"
          tipText=""
          backgroundImage="linear-gradient(90deg, #FF2699 0%, #FF77BF 100%);"
          shadowColor="rgba(255,160,97,0.50)"
        />
      </div>
      <div class="chart-box">
        <Canvasdash
          :id="'chartstop3'"
          :value="
            parseFloat(option_stat.award_qty) == 0
              ? 0
              : parseFloat(option_stat.actual_qty) /
                parseFloat(option_stat.award_qty)
          "
          :height="74"
          :color1="'rgba(255,38,153,1)'"
          :color2="'rgba(255,119,191,1)'"
          :shadowcolor="'rgba(254,118,191,0.5)'"
          :shadowcolor2="'rgba(255,119,191,0.3)'"
          :name="'已成熟'"
        ></Canvasdash>
      </div>
    </div>
  </div>
</template>
<script>
import ChartNum from "@/components/business/chart-num";
// import RingLine from "@/components/charts/RingLine";
import Canvasdash from "@/components/charts/canvasdash";
import Ring from "@/components/charts/ring";
import api from "@/api";
export default {
  components: {
    ChartNum,
    // RingLine,
    Ring,
    Canvasdash,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    ["data.plan_id"](val, oval) {
      if (val !== oval) this.getDetail();
    },
  },
  computed: {
    optionStatRe() {
      const arr = JSON.parse(JSON.stringify(this.optionStat));
      return arr.reverse();
    },
  },
  data() {
    return {
      option_stat: {},
      optionStat: [],
      loading: true,
    };
  },
  mounted() {},
  methods: {
    getDetail() {
      const { plan_id } = this.data;
      if (!plan_id) {
        this.loading = false;
        return;
      }
      this.loading = true;
      api.incentive
        .statPlan({ plan_id, type: "stock" })
        .then((res) => {
          if (res) {
            // console.log(res);
            this.loading = false;
            this.option_stat = res;
            this.optionStat = [
              {
                name: "剩余总数(股)",
                percent: Number(this.data.surplus),
                color: "l(0) 0:#57EB99 1:#0DCD63",
                lineColor:
                  "linear-gradient(rgb(236, 240, 243), rgb(236, 240, 243));",
                shadowColor: "rgba(0,0,0,0)",
              },
              {
                name: "授予总数(股)",
                percent: Number(res.award_qty),

                tipText:
                  "<div>统计中的“已授予数”与授予记录中的“授予股<br />数”不是简单的汇总关系。</div><div>考虑了“已终止”状态的授予记录中“已回收数”<br />对统计中“已授予数”的影响。</div>",
                color: "l(0) 0:#866BFF 1:#5A39EB",
                lineColor:
                  "linear-gradient(207deg, #866BFF 13%, #5A39EB 100%);",
                shadowColor: "rgba(134,107,255,0.50);",
              },
              {
                name: "总股数(股)",
                percent: Number(this.data.total),
              },
            ];
          }
          // console.log(res);
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.right-sta {
  .chart_title {
    margin-bottom: 17px;
  }
  /deep/ .chart-num {
    margin-bottom: 16px;
  }
  .mt-20 {
    margin-top: 33px;
  }
}
</style>
