<template>
  <div class="aw_stock">
    <search-collapse :senior="true" @handleSearch="getList">
      <template #search-left>
        <el-button
          type="primary"
          class="add"
          icon="el-icon-plus"
          @click="handleEdit"
          >新建</el-button
        >
        <el-button class="add export" plain @click="handleImport">
          <div class="flex-h flex-vc">
            <!-- <img src="@/assets/images/20@2x.png" alt="" class="img_icon" /> -->
            <i class="iconfont icon-piliangshouyu img_icon"></i>
            批量授予
          </div>
        </el-button>
      </template>
      <el-form :inline="true" :model="queryCondition" class="el-form-custom">
        <el-form-item label="授予状态" class="hasLable">
          <el-select v-model="queryCondition.status" placeholder="全部">
            <el-option
              v-for="item in $addAllSelect(tableMng.getTable('award_status'))"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-input
            v-model="queryCondition.plan_code"
            placeholder="请输入完整的计划编号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-input
            v-model="queryCondition.phone"
            placeholder="请输入手机号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-input
            v-model="queryCondition.code"
            placeholder="请输入完整的授予编号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="在职状态" class="hasLable">
          <el-select v-model="queryCondition.employ_status" placeholder="全部">
            <el-option
              v-for="item in $addAllSelect(tableMng.getTable('employ_status'))"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="最近归属日" class="hasLable">
          <div class="flex-h flex-vc">
            <el-date-picker
              v-model="queryCondition.sdate"
              type="date"
              placeholder="开始日期"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptionsStart"
            />
            <span style="margin: 0 10px"> 至 </span>
            <el-date-picker
              v-model="queryCondition.edate"
              placeholder="结束日期"
              type="date"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptionsEnd"
            />
          </div>
        </el-form-item>
        <!-- <div
          style="width: 100px"
          v-for="item in [1, 2, 3, 4, 5]"
          :key="item"
        ></div> -->
      </el-form>
    </search-collapse>
    <div class="row" ref="row" :style="`height:${rowHeight}`">
      <div class="col" v-show="showDataDemo">
        <div class="col-left">
          <el-table
            :data="tableData"
            highlight-current-row
            v-loading="tableLoading"
            @sort-change="changeSort"
            @row-click="rowClick"
            :row-class-name="tableRowClassName"
            ref="table"
            height="calc(100% - 64px)"
            class="custom_border"
          >
            <el-table-column
              prop="phone"
              label="手机号"
              min-width="140px"
              sortable="custom"
            />
            <af-table-column prop="user_name" label="姓名">
              <!-- <template slot-scope="scope">
                <TextOverflow :limit="20" :text="scope.row.name || '- -'" />
              </template> -->
            </af-table-column>
            <af-table-column prop="department" label="所属部门">
              <template slot-scope="scope">
                <chacter-overflow
                  :limit="20"
                  :text="scope.row.department || '- -'"
                />
              </template>
            </af-table-column>
            <el-table-column
              prop="employ_status"
              label="在职状态"
              min-width="130px"
              sortable="custom"
            >
              <template slot-scope="scope">
                {{
                  tableMng.getNameById("employ_status", scope.row.employ_status)
                }}
              </template>
            </el-table-column>
            <el-table-column prop="id" label="操作" min-width="125px">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="handleEdit(scope.$index, scope.row)"
                >
                  修改
                </el-button>
                <el-divider direction="vertical"></el-divider>
                <el-button
                  type="text"
                  class="del_button"
                  @click="handleDelete(scope.$index, scope.row)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>

            <af-table-column prop="code" label="授予编号" sortable="custom" />
            <el-table-column
              prop="status"
              label="授予状态"
              min-width="130px"
              sortable="custom"
            >
              <template slot-scope="scope">
                <div
                  class="flex-h flex-vc"
                  :class="`${
                    scope.row.status === 'stop' ? 'disabled_color' : ''
                  }`"
                >
                  {{ tableMng.getNameById("award_status", scope.row.status) }}

                  <el-button
                    type="text"
                    @click="handleStatus(scope.$index, scope.row)"
                  >
                    <img
                      src="@/assets/images/edit.png"
                      alt=""
                      class="edit_img"
                    />
                  </el-button>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="currency"
              label="币种"
              sortable="custom"
              min-width="100px"
            >
              <template slot-scope="scope">
                {{ tableMng.getNameById("currency_list", scope.row.currency) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="price"
              label="认购参考价"
              align="right"
              min-width="145px"
              sortable="custom"
            />
            <el-table-column
              prop="buy_mode"
              label="出资方式"
              min-width="130px"
              sortable="custom"
            >
              <template slot-scope="scope">
                {{ tableMng.getNameById("buy_mode", scope.row.buy_mode) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="award_date"
              label="授予日期"
              min-width="135px"
              sortable="custom"
            />
            <el-table-column
              prop="start_date"
              label="起算日期"
              min-width="135px"
              sortable="custom"
            />
            <af-table-column
              prop="nearest_mature_date"
              label="最近归属日"
              sortable="custom"
            >
              <template slot-scope="scope">
                {{ scope.row.nearest_mature_date || "---" }}
              </template>
            </af-table-column>
            <af-table-column
              prop="award_count"
              label="授予股数"
              sortable="custom"
            />
            <af-table-column
              prop="actual_count"
              label="已归属数"
              sortable="custom"
            />
            <af-table-column
              prop="recyclable_count"
              label="可回收数"
              sortable="custom"
            />
            <af-table-column
              prop="recycle_count"
              label="已回收数"
              sortable="custom"
            />
            <af-table-column prop="condition_name" label="归属条件">
              <template slot-scope="scope">
                <chacter-overflow
                  :limit="20"
                  :text="scope.row.condition_name || '- -'"
                />
              </template>
            </af-table-column>
            <af-table-column prop="condition_content" label="条件说明">
              <template slot-scope="scope">
                <TextOverflow
                  :limit="1"
                  :text="conditionShow(scope.row.condition_content) || '- -'"
                />
              </template>
            </af-table-column>
            <af-table-column prop="remark" label="备注">
              <template slot-scope="scope">
                <TextOverflow :limit="20" :text="scope.row.remark || '- -'" />
              </template>
            </af-table-column>
            <el-table-column
              prop="plan_code"
              label="计划编号"
              sortable="custom"
              min-width="160px"
            />
            <af-table-column prop="plan_name" label="计划名称">
              <template slot-scope="scope">
                <chacter-overflow
                  :limit="20"
                  :text="scope.row.plan_name || '- -'"
                />
              </template>
            </af-table-column>
            <af-table-column prop="platform_name" label="持股平台">
              <template slot-scope="scope">
                <chacter-overflow
                  :limit="20"
                  :text="scope.row.platform_name || '- -'"
                />
              </template>
            </af-table-column>
            <el-table-column
              prop="hold_mode"
              label="持股方式"
              min-width="130px"
              sortable="custom"
            >
              <template slot-scope="scope">
                {{ tableMng.getNameById("hold_mode", scope.row.hold_mode) }}
              </template>
            </el-table-column>

            <el-table-column prop="ctime" label="创建时间" min-width="195px" />
            <af-table-column prop="creator" label="创建人" min-width="80px" />
            <el-table-column prop="mtime" label="修改时间" min-width="195px" />
            <af-table-column prop="modifier" label="修改人" min-width="80px" />
          </el-table>
          <!-- 分页 -->
          <pagination
            :total="total"
            :page-number.sync="queryCondition.page"
            :page-size.sync="queryCondition.limit"
            @pagination="getList"
          />
        </div>
        <div class="col-right">
          <el-tabs v-model="editableTabsValue" type="card">
            <el-tab-pane label="归属详情" name="0">
              <Ascription :data="relation_data" />
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <Empty v-show="!showDataDemo && requested" />
    </div>
    <Edit
      :data="edit_data"
      :visible="visible"
      @onClose="handleClose"
      @onSave="handleSave"
    />
    <FileImport
      :visible="import_visible"
      @onClose="handleClose"
      @onSave="handleSave"
    />
  </div>
</template>
<script>
import SearchCollapse from "@/components/base/search-collapse";
import Empty from "@/components/base/empty";
import Ascription from "./components/Ascription";
import Edit from "./components/Edit.vue";
import Pagination from "@/components/base/pagination";
import FileImport from "./components/FileImport.vue";
import tableLife from "@/mixins/tableLife.js";
import TextOverflow from "@/components/base/text-overflow";
import { conditionShow } from "@/utils/core";
import api from "@/api";
export default {
  mixins: [tableLife],
  components: {
    SearchCollapse,
    TextOverflow,
    Pagination,
    FileImport,
    Ascription,
    Empty,
    Edit,
  },
  data() {
    return {
      conditionShow,
      editableTabsValue: 0,
      defaultSortWord: "code",
      total: 0,
      dayDate: "",
      pickerOptionsStart: {
        disabledDate: (time) => {
          if (this.queryCondition.edate) {
            //如果结束时间不为空，则小于结束时间
            return this.$dayjs(this.queryCondition.edate) < time.getTime();
          }
        },
      },
      pickerOptionsEnd: {
        disabledDate: (time) => {
          if (this.queryCondition.sdate) {
            //如果开始时间不为空，则结束时间大于开始时间
            return this.$dayjs(this.queryCondition.sdate) > time.getTime();
          }
        },
      },
      queryCondition: {
        status: "",
        code: "",
        plan_code: "",
        phone: "",
        employ_status: "",
        sdate: "",
        edate: "",
        sort: "code",
        order: "desc",
        page: 1,
        limit: 20,
      },
      visible: false,
      import_visible: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getApiTableData() {
      return api.awarded.stockAwardList(this.queryCondition);
      // const data = await api.awarded.stockAwardList(this.queryCondition);
      // return data;
    },
    // 时间改变
    dayChange(val) {
      if (val && val.length > 1) {
        this.queryCondition.sdate = val[0];
        this.queryCondition.edate = val[1];
      } else {
        this.queryCondition.sdate = "";
        this.queryCondition.edate = "";
      }
    },
    handleDelete(index, row) {
      let award_id = row.award_id;
      if (award_id) {
        this.$confirm(`确定删除吗？`, "删除", {})
          .then(async () => {
            await api.awarded.stockAwardDel({ award_id });
            this.$message.success("删除成功！");
            this.getList();
          })
          .catch(() => {});
      }
    },
    handleImport() {
      this.import_visible = true;
    },
    handleStatus(index, row) {
      let award_id = row.award_id,
        status = row.status;
      if (status === "stop") return;
      if (award_id) {
        this.$confirm(
          `<div><span style="width:70px;display:inline-block;text-align:right;color:#898A8C;">授予编号：</span>${row.code}</div><div style="margin-top:20px">授予状态仅可修改为“终止”。<br/>修改后，全部批次中未归属的股票将被回收到激励计划中，并且操作不可逆!</div><div style="margin-top:20px">确定将状态修改为“终止”吗？</div>`,
          "修改授予状态",
          {
            dangerouslyUseHTMLString: true,
          }
        )
          .then(async () => {
            await api.awarded.stockAwardStop({ award_id });
            this.$message.success("操作成功！");
            this.getList();
          })
          .catch(() => {});
      }
    },
    // 保存
    handleSave() {
      this.handleClose();
      this.getList();
    },
    // 关闭编辑模态窗
    handleClose() {
      this.visible = false;
      this.import_visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.aw_stock {
  height: 100%;
  .edit_img {
    width: 20px;
    // margin-top: 1px;
    display: inline-block;
  }
  .el-form-custom {
    // text-align: right;
    /deep/ .el-form-item {
      width: calc(25% - 16px);
      .el-form-item__content {
        width: 100%;
      }
      &.hasLable .el-form-item__content {
        width: calc(100% - 75px);
      }
      &:nth-child(5) {
        margin-bottom: 0;
      }
      &:last-child {
        width: auto;
        margin-bottom: 0;
        .el-form-item__content {
          width: auto;
        }
      }
    }
  }
  .export {
    padding-left: 12px;
    .img_icon {
      width: 20px;
      font-size: 22px;
      margin-right: 8px;
    }
  }
}
</style>
