<template>
  <div class="exercise">
    <el-table
      :data="tableData"
      highlight-current-row
      v-loading="tableLoading"
      @sort-change="changeSort"
      @row-click="rowClick"
      :row-class-name="tableRowClassName"
      ref="table"
      class="custom_border mt-4"
      height="calc(100% - 67px)"
    >
      <el-table-column prop="" label="归属操作" min-width="125px">
        <template slot-scope="scope">
          <el-button
            v-if="status === 'normal' && scope.row.status !== 'R'"
            type="text"
            @click="handleEdit(scope.$index, { ...scope.row, otherInfo: data })"
          >
            {{ scope.row.status === "Y" ? "修改" : "归属" }}
          </el-button>
          <span v-else>---</span>
          <el-divider direction="vertical"></el-divider>
          <el-button
            v-if="
              status === 'normal' &&
              scope.row.status !== 'R' &&
              scope.row.recyclable_count
            "
            type="text"
            @click="handleRecovery(scope.$index, scope.row)"
          >
            回收
          </el-button>
          <span v-else>---</span>
        </template>
      </el-table-column>
      <af-table-column prop="seq" label="批次" />
      <af-table-column prop="mature_date" label="归属日期" />
      <af-table-column prop="mature_rate" label="可归属比例">
        <template slot-scope="scope">
          {{ scope.row.mature_rate | number_format }}%
        </template>
      </af-table-column>
      <af-table-column prop="expect_count" label="可归属数" />
      <af-table-column prop="buy_mode" label="实际出资方式">
        <template slot-scope="scope">
          {{ tableMng.getNameById("buy_mode", scope.row.buy_mode) }}
        </template>
      </af-table-column>
      <af-table-column prop="price" label="实际认购价格" align="right">
        <template slot-scope="scope">
          {{
            scope.row.status === "N" || !scope.row.actual_count
              ? "---"
              : scope.row.price
          }}
        </template>
      </af-table-column>
      <af-table-column prop="actual_count" label="已归属数" />
      <af-table-column prop="recyclable_count" label="可回收数" />
      <af-table-column prop="recycle_count" label="已回收数" />
      <af-table-column prop="status" label="状态">
        <template slot-scope="scope">
          {{ tableMng.getNameById("mature_status", scope.row.status) }}
        </template>
      </af-table-column>

      <el-table-column prop="ctime" label="创建时间" min-width="195px" />
      <af-table-column prop="creator" label="创建人" min-width="80px" />
      <el-table-column prop="mtime" label="修改时间" min-width="195px" />
      <af-table-column prop="modifier" label="修改人" min-width="80px" />
    </el-table>
    <AscriptionEdit
      :data="edit_data"
      :visible="visible"
      @onClose="handleClose"
      @onSave="handleSave"
    />
  </div>
</template>
<script>
import api from "@/api";
import AscriptionEdit from "./AscriptionEdit.vue";
import tableLife from "@/mixins/tableLife.js";
export default {
  props: {
    data: {
      type: Object,
      default: () => null,
    },
  },
  watch: {
    data(value) {
      const { award_id } = value;
      if (award_id) this.getList();
    },
  },
  mixins: [tableLife],
  components: {
    AscriptionEdit,
  },

  data() {
    return {
      total: 0,
      queryCondition: {
        sort: "ctime",
        order: "desc",
      },
      defaultSortWord: "",
      visible: false,
      status: "",
    };
  },
  mounted() {},
  methods: {
    async getApiTableData() {
      if (!this.data.award_id) return;
      this.status = this.data.status;
      return api.awarded.stockMatureList({
        award_id: this.data.award_id,
      });
      // const data = await api.awarded.stockMatureList({
      //   award_id: this.data.award_id,
      // });
      // return data;
    },
    // 回收
    handleRecovery(index, row) {
      const id = row.id,
        seq = row.seq,
        code = this.data.code;
      if (id) {
        this.$confirm(
          `<div><span style="width:70px;display:inline-block;text-align:right;color:#898A8C;">授予编号：</span>${code}</div><div><span style="width:70px;display:inline-block;text-align:right;color:#898A8C;">批次：</span>${seq}</div><div style="margin-top:20px">未归属的股票将被回收到激励计划中，<br />并且操作不可逆！</div><div style="margin-top:20px">确定“回收”吗？</div>`,
          "批次股票回收",
          {
            dangerouslyUseHTMLString: true,
          }
        )
          .then(async () => {
            await api.awarded.stockMatureRecycle({ id });
            this.$message.success("操作成功！");
            this.$parent.$parent.$parent.getList();
            this.getList();
          })
          .catch(() => {});
      }
    },
    // 保存
    handleSave() {
      this.handleClose();
      this.$parent.$parent.$parent.getList();
      this.getList();
    },
    // 关闭编辑模态窗
    handleClose() {
      this.visible = false;
      this.import_visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.exercise {
  height: 100%;
  .chart_title {
    .title_content {
      font-size: 20px;
      color: #333333;
      line-height: 28px;
      font-weight: 600;
      margin-left: 24px;
    }
  }
}
</style>
