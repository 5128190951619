<template>
  <el-dialog
    class="custom-dialog"
    :title="`${data && data.plan_id ? '修改股票激励计划' : '新建股票激励计划'}`"
    :visible.sync="visible"
    :before-close="handleBeforeClose"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
  >
    <div>
      <el-form
        ref="form"
        :model="info"
        v-loading="getDetailLoading"
        :rules="formRules"
        hide-required-asterisk
        label-width="85px"
      >
        <el-form-item label="计划名称" prop="name">
          <el-input
            label=""
            v-model="info.name"
            placeholder="请输入激励计划名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="持股平台" prop="platform_id">
          <el-select v-model="info.platform_id" placeholder="请选择支持平台">
            <el-option
              v-for="item in platformList"
              :key="item.platform_id"
              :label="item.name"
              :value="item.platform_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="持股方式" prop="mode">
          {{ tableMng.getNameById("hold_mode", mode) || "---" }}
        </el-form-item>
        <el-form-item label="币种" prop="currency">
          <el-select v-model="info.currency" placeholder="请输入币种">
            <el-option
              v-for="item in currency_list"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label=""
          prop="other_currency"
          v-if="info.currency === 'other'"
        >
          <el-input
            label=""
            v-model="info.other_currency"
            placeholder="请输入币种"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="认购参考价" prop="price">
          <el-input
            v-model="info.price"
            v-number-format="{ limit: 4 }"
            placeholder="仅可输入数字，最多4位小数"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="总股数" prop="total">
          <el-input
            v-model="info.total"
            v-number-format="{ limit: 0, min: 1 }"
            placeholder="仅可输入数字，保留整数"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleBeforeClose">取消</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import api from "@/api";
import dialogLife from "@/mixins/dialogLife";
import { deepClone, formatDecimal } from "@/utils/core";
const defaultInfo = {
  currency: "CNY",
  other_currency: "",
  name: "",
  total: "",
  price: "",
  plan_id: "",
  platform_id: "",
};

export default {
  mixins: [dialogLife],
  components: {},
  data() {
    return {
      infoBackup: deepClone(defaultInfo),
      info: deepClone(defaultInfo),
      platformList: [],
      mode: "",
      formRules: {
        name: [
          { required: true, message: "请输入激励计划名称", trigger: "blur" },
        ],
        platform_id: [
          { required: true, message: "请选择支持平台", trigger: "change" },
        ],
        currency: [
          { required: true, message: "请选择币种", trigger: "change" },
        ],
        other_currency: [
          { required: true, message: "请输入币种", trigger: "blur" },
        ],
        price: [
          {
            required: true,
            message: "请输入行权参考价",
            trigger: "blur",
          },
        ],
        total: [
          {
            required: true,
            message: "请输入总股数",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    currency_list() {
      return this.tableMng.getTable("currency_list");
      // .concat([{ id: "other", name: "其他" }]);
    },
  },
  watch: {
    ["info.platform_id"](val) {
      if (val)
        this.mode = this.platformList.filter(
          (item) => item.platform_id === val
        )[0].mode;
    },
  },
  created() {
    this.getPlatformList();
  },
  methods: {
    async getDetail() {
      if (!this.data || !this.data.plan_id) return;
      this.getDetailLoading = true;
      let data = deepClone(this.data);
      this.info = data;
      this.infoBackup = data;
      this.getDetailLoading = false;
    },
    getPlatformList() {
      api.incentive.platformList().then((res) => {
        if (res.list) {
          const list = res.list.filter((item) => item.status === "1");
          this.platformList = list;
        }
      });
    },
    async submitMethod() {
      const {
        currency,
        name,
        total,
        price,
        plan_id,
        platform_id,
        other_currency,
      } = this.info;
      const currencyWord = currency === "other" ? other_currency : currency;
      if (plan_id)
        await api.incentive.stockPlanEdit({
          currency: currencyWord,
          name,
          total: formatDecimal(total, 0),
          price: formatDecimal(price, 4),
          plan_id,
          platform_id,
        });
      else
        await api.incentive.stockPlanAdd({
          currency: currencyWord,
          name,
          total: formatDecimal(total, 0),
          price: formatDecimal(price, 4),
          platform_id,
        });
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.info = deepClone(defaultInfo);
      this.infoBackup = deepClone(defaultInfo);
      this.$emit("onClose");
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-dialog {
  .el-dialog__footer {
    // padding: 0;
  }
}
</style>
